import React, { FormEvent } from 'react';
import { Registration } from '../../../stwh-services';
import { Container, Form, FormGroup, Row, Col, Alert } from 'react-bootstrap';
import { RegistrationManager } from '../backend/registrationManager';
import { Link } from 'react-router-dom';

interface RegistrationFormState {
    userId: string;
    password: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    passwordCheck: string;
    userIdUnique: boolean,
    emailAddressUnique: boolean,
    formValid: boolean,
    formComplete: boolean,
}

export interface RegistrationData {
    userId: string;
    password: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
}

interface RegistrationFormProps {
    onUpdate: (reg: RegistrationData) => void ,
    onQuit: () => void,
}
export class RegistrationForm extends React.Component<RegistrationFormProps, RegistrationFormState> {

    registrationManager = new RegistrationManager();
    constructor(props: { registration: Registration }) {
        super(props as any);

        this.state = {
            userId: '',
            password: '',
            firstName: '',
            lastName: '',
            emailAddress: '',
            passwordCheck: '',
            userIdUnique: true,
            emailAddressUnique: true,
            formValid: true,
            formComplete: false,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    handleSubmit(event: FormEvent) {
        event.preventDefault();

        const formValid = this.validateForm();
        this.setState({ formValid: formValid });
        if (!formValid) {
            return;
        }

        if (this.state.userId) {
            this.registrationManager.checkUniqueFields(this.state.userId, this.state.emailAddress).then(async (result): Promise<void> => {
                if (result === 'REGISTERED') {
                    this.setState({
                        userIdUnique: false,
                        formValid: false,
                    });
                } else if (result === 'EMAILUSED') {
                    this.setState({
                        emailAddressUnique: false,
                        formValid: false,
                    });
                } else {
                    await this.registrationManager.createRegistration(
                        this.state.userId,
                        this.state.password,
                        this.state.emailAddress,
                        this.state.firstName,
                        this.state.lastName);
                    this.setState({ formComplete: true });
                    this.props.onUpdate({
                        userId: this.state.userId,
                        password: this.state.password,
                        emailAddress: this.state.emailAddress,
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                    })
                }
            })
            // this.registrationManager.alreadyRegistered(this.state.userId).then(async (result): Promise<void> => {
            //     if (result) {
            //         this.setState({
            //             userIdUnique: false,
            //             formValid: false,
            //         });
            //     } else {
            //         await this.registrationManager.createRegistration(
            //             this.state.userId,
            //             this.state.password,
            //             this.state.emailAddress,
            //             this.state.firstName,
            //             this.state.lastName);
            //         this.setState({ formComplete: true });
            //         this.props.onUpdate({
            //             userId: this.state.userId,
            //             password: this.state.password,
            //             emailAddress: this.state.emailAddress,
            //             firstName: this.state.firstName,
            //             lastName: this.state.lastName,
            //         })
            //         //this.props.onUpdate(this.state.userId);
            //     }
            // });
        }
    }

    handleCancel(event: FormEvent) {
        this.props.onQuit();
    }

    handleChange(event: FormEvent) {
        const target: HTMLInputElement = event.target as HTMLInputElement;
        const value = target.value;
        const name = target.name;

        this.setState({ [name]: value, formValid: true } as unknown as RegistrationFormState);
        if (name === 'userId') {
            this.setState({ userIdUnique: true });
        }
    }

    validateEmailAddress(emailAddress: string | undefined): boolean {
        let regex = /[A-Za-z0-9]+@[A-Za-z0-9]+\.[A-Za-z0-9]+/i;
        if (emailAddress) {
            return regex.test(emailAddress);
        } else {
            return true;
        }
    }

    validateForm(): boolean {

        for (const key in this.state) {
            if ((this.state as any)[key] === '') {
                return false;
            }
        }

        if (!this.validateEmailAddress(this.state.emailAddress)) {
            return false;
        }

        if (this.state.password !== this.state.passwordCheck) {
            return false;
        }

        return true;
    }

    // componentDidUpdate(prevProps: Readonly<{ registration: Registration; onUpdate: (registration: Partial<Registration>) => void; }>, prevState: Readonly<Partial<Registration>>, snapshot?: any): void {
    //     if (this.props.registration.userId !== prevProps.registration.userId) {
    //         this.setState(this.props.registration);
    //     }
    // }

    render() {
        //const newItem = this.props.registration.userId === '';

        return (
            <Container className='bg-light'>
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <label htmlFor='userId'>Choose a User ID</label><div hidden={this.state.userIdUnique} className='form-error-field'>* User ID Already Registered *</div>
                        <input type='text' className='form-control' id='userId' name='userId' onChange={this.handleChange} value={this.state.userId} />
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor='password'>Password</label>
                        <input type='password' className='form-control' id='password' name='password' onChange={this.handleChange} value={this.state.password} />
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor='password'>Retype Password</label><div hidden={this.state.password === this.state.passwordCheck} className='form-error-field'>* Passwords do not match *</div>
                        <input type='password' className='form-control' id='passwordCheck' name='passwordCheck' onChange={this.handleChange} value={this.state.passwordCheck} />
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor='firstName'>First name</label>
                        <input type='text' className='form-control' onChange={this.handleChange} id='firstName' name='firstName' value={this.state.firstName} />
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor='lastName'>Last name</label>
                        <input type='text' className='form-control' onChange={this.handleChange} id='lastName' name='lastName' value={this.state.lastName} />
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor='emailAddress'>Email Address</label><div hidden={this.validateEmailAddress(this.state.emailAddress)} className='form-error-field'>* Invalid email address *</div>
                        <div hidden={this.state.emailAddressUnique} className='form-error-field'>* Email address already taken *</div>
                        <input type='email' className='form-control' onChange={this.handleChange} id='emailAddress' name='emailAddress' value={this.state.emailAddress} />
                    </FormGroup>
                    <Alert hidden={this.state.formValid}>Correct errors and make sure all fields are filled supplied.</Alert>
                    <Row className='py-2'>
                        <Col sm={2}></Col>
                        <Col sm={8} className='text-center'>
                            <div>
                                <input className='btn btn-primary mx-2' type='submit' value='Save' />
                                <Link className='btn btn-warning cancel' to={'/'}>Cancel</Link>
                                {/*<input className='btn btn-warning cancel' type='button' value='Cancel' onClick={this.handleCancel} /> */}
                            </div>
                        </Col>
                        <Col sm={2}></Col>
                    </Row>
                </Form>
            </Container>
        )
    }

}