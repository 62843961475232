import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import create_project_1 from '../assets/create_project_1.jpg';
import create_project_2 from '../assets/create_project_2.jpg';
import create_project_3 from '../assets/create_project_3.jpg';
import create_project_4 from '../assets/create_project_4.jpg';
import create_project_5 from '../assets/create_project_5.jpg';
import create_project_6 from '../assets/create_project_6.jpg';
import create_project_7 from '../assets/create_project_7.jpg';
import create_project_8 from '../assets/create_project_8.jpg';
import create_project_9 from '../assets/create_project_9.jpg';
import app_setup_1 from '../assets/app_setup_1.jpg';
//import app_setup_2 from '../assets/app_setup_2.jpg';
// import app_setup_3 from '../assets/app_setup_3.jpg';
// import app_setup_4 from '../assets/app_setup_4.jpg';
// import app_setup_5 from '../assets/app_setup_5.jpg';
import app_setup_6 from '../assets/app_setup_6.jpg';
import app_setup_7 from '../assets/app_setup_7.jpg';
import app_setup_8 from '../assets/app_setup_8.jpg';

interface HowToStepProps {
    image: any;
    children: any;
}

interface HowToStepSideBySideProps {
    image: any;
    children: any;
    image2?: any;
}

function HowToStep(props: HowToStepProps) {
    return (
        <div className='text-muted my-4'>
            <hr />
            <Row>
                <Col>
                    <li>{props.children}</li>
                </Col>
                <Col>
                    <img src={props.image} alt='Create Project Button' width={400} />
                </Col>
            </Row>
        </div>
    )
}

function HowToStepSideBySide(props: HowToStepSideBySideProps) {
    return (
        <div className='text-muted my-4'>
            <hr />
            <Row>
                <Col md='4'>
                    <li>{props.children}</li>
                </Col>
                <Col md='4'>
                    <img src={props.image} alt='Create Project Button' width={175} />
                </Col>
                <Col md='4' hidden={!props.image2}>
                    <img src={props.image2} alt='Create Project Button' width={175} />
                </Col>
            </Row>
        </div>
    )
}

export class HowTo extends React.Component<{onReturn: () => void},{}> {

    render() {
        return (
            <Container>
                <ol>
                    <div className='text-center'>
                        <h2>How to Set Up</h2> <h2>Homebridge SmartThings Webhook</h2>
                    </div>
                    <Row className='text-muted my-4'>
                        <p>The webhook capability from SmartThings requires a configured “Smart App” to first be created
                            in a developer account associated with your Samsung account, and later to be installed via
                            the SmartThings App.  You need to set up a new Smart App and purchase a Webhook Access Token from this site.
                        </p>
                        <p>
                            The order does not matter but once you set up the Smart App, you will need to enter your Webhook Access Token into
                            the SmartThings App on your mobile device.
                        </p>
                        <p>
                            Follow the steps below, and make sure to puchase the Webhook Access Token before setting up your devices in the SmartThings 
                            mobile app.
                        </p>
                    </Row>
                    <HowToStep image={create_project_1}>
                        Open up the <a href="https://smartthings.developer.samsung.com/workspace" target={'_blank'} rel={'noreferrer'}>Developer Workspace</a> page in your browser and sign in with your Samsung account.
                        <li>Click on "Create a New Project"</li>
                    </HowToStep>
                    <HowToStep image={create_project_2}>
                        Select “Automation for the SmartThings App”
                    </HowToStep>
                    <HowToStep image={create_project_3}>
                        Create a name for the smart app like "Homebridge Webhook"
                    </HowToStep>
                    <HowToStep image={create_project_4}>
                        Click on the "Register App button to set up the url for the app and perform the registration"
                    </HowToStep>
                    <HowToStep image={create_project_7}>
                        Select “WebHook Endpoint”, and enter this URL as the Target URL: <code>https://stwh.kleinstudios.net/api</code>
                        <br></br>and click “NEXT”
                    </HowToStep>
                    <HowToStep image={create_project_5}>
                        Enter a display name and description which will appear in your SmartThings mobile app.
                        Then ...
                    </HowToStep>
                    <HowToStep image={create_project_6}>
                        Add the r:devices and r:locations permissions, then click “NEXT”.
                    </HowToStep>
                    <li>
                        Click "SAVE" on the next screen, the "Go To Project Overview" on the next one
                    </li>
                    <HowToStep image={create_project_8}>
                        You should be at the main screen for the app.  In the "Automation" section, click on
                        "VERIFY APP REGISTRATION".  This step might take a few seconds to complete, and if it
                        times out, just try it again.
                    </HowToStep>
                    <HowToStep image={create_project_9}>
                        Finally, deploy the app to test which will make it available in your SmartThings App.
                    </HowToStep>
                    <li>
                        Before you can start sending events to your bridge, you must purchase a Webhook Access token by 
                        registering <div className='btn-link d-inline' onClick={this.props.onReturn}>here</div>.  If you already have one, continue on.
                    </li>
                </ol>
                <hr></hr>
                <h3 className='my-3'>Configure the app in the SmartThings app</h3>
                <p className='text-muted'>
                    In the next set of steps, you will configure the app within the SmartThings mobile app.  You need your
                    Webhook Access Token to do this. 
                </p>
                <ol>
                    <HowToStepSideBySide image={app_setup_1} image2={app_setup_7}>
                        This step varies depending upon if you have the latest version of the SmartThings App or not.
                        Open up the SmartThings app on your mobile device.  Tap the '+' button on the top of the screen
                        and touch 'Create routine'.
                        <li>
                            If you don't see the "Discover" tab at the top of the screen, then touch the Automations tab at the bottom.
                            You'll see a circle icon at the top right-hand corner of that screen.  Touch
                        </li>
                        <li>
                            If you do have the "Discover" touch that.  In either case, scroll all the way to the bottom, and
                            there you should see a box with just the work "Label" in it or it may dislay the name you
                            set up in step 7 above.
                        </li>
                    </HowToStepSideBySide>

                    <HowToStepSideBySide image={app_setup_8} image2={app_setup_6}>
                        Enter the Webhook Access Token from your purchase on this site and touch 'Done'.
                        <li>
                            Finally, you are on a review screen.  You can toucn on "See your devices" to see which
                            devices you selected.  At the bottom of this screen (scroll down to the bottom) touch "Allow"
                        </li>
                    </HowToStepSideBySide>
                </ol>

                <hr></hr>
                <h3>Configure the Plugin</h3>
                <p>
                    The last step is to configure the Homebridge Smartthings Plugin to connect to the web hook service and
                    NOT to poll your devices.  To do this, make sure you are running release 1.5 or higher.  Earlier versions
                    do not support the web hook.
                </p>
                <p>
                    Go into the settings for the plugin and enter your Webhook Token there.  <strong>IMPORTANT: the Webhook Token is NOT
                    the same as the SmartThigns access token.  Make sure to set the correct parameter in the config for the plugin.</strong>  You 
                    will see two separate lines in the file:</p>
                    <code>"AccessToken": "XXXXXXXX-fab3-480b-96db-23ad45fXXXXX",<br></br>
                    "WebhookToken": "XXXXXXXX-a23e-11ed-9176-33c5088XXXXX",</code><p></p>
                <p>
                    <strong>Make sure to set the line that is labelled, "WebhookToken".</strong>
                </p>
                <p>
                    Nowrestart Homebridge.  Once 
                    the plugin is fully loaded, it will no longer poll your devices, but instead request updates from
                    the webhook server.
                </p>
            </Container>
        )
    }
}