import React, { FormEvent } from 'react';
import { Alert, Container, Form, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom';
import { RegistrationManager } from '../backend/registrationManager';

interface ChangePasswordPageProps {
    token: string;
    emailAddress: string;
}

interface ChangePasswordPageState {
    userId: string;
    password: string;
    retypePassword: string;
    errorMessage: string;
    complete: boolean;
}

export class ChangePasswordPage extends React.Component<ChangePasswordPageProps, ChangePasswordPageState> {
    private _registrationManager: RegistrationManager = new RegistrationManager();

    constructor(props: any) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            userId: '',
            password: '',
            retypePassword: '',
            errorMessage: '',
            complete: false,
        }
    }

    async componentDidMount(): Promise<void> {
        await this.validateToken();
    }

    async validateToken() {
        const userId = await this._registrationManager.validateChangePasswordToken(this.props.token);

        if (!userId) {
            this.setState({errorMessage: 'Link is invalid'});
        } else {
            this.setState({userId: userId});
        }
    }

    handleChange(event: any) {
        this.setState({[event.target.id]: event.target.value} as Pick<ChangePasswordPageState, keyof ChangePasswordPageState>);
    }

    async handleSubmit(event: FormEvent) {
        event.preventDefault();
        if (this.state.userId !== '') {
            const success = await this._registrationManager.changePassword(this.state.userId, this.props.token, this.state.password);
            if (!success) {
                this.setState({errorMessage: 'Could not change password.  Try again later.'});
            } else {
                this.setState({complete: true});
            }
        }
    }

    render() {
        // eslint-disable-next-line no-restricted-globals
        console.log(`in change password ${this.props.token}`);
        return (
            <Container>
                { this.state.complete && <Navigate to='/login' />}
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup controlId='password'>
                        <FormLabel>New password</FormLabel>
                        <FormControl disabled={this.state.userId === ''|| this.state.errorMessage !== ''} type='password' onChange={this.handleChange} value={this.state.password}></FormControl>
                    </FormGroup>
                    <FormGroup controlId='retypePassword'>
                        <FormLabel>Retype password</FormLabel>
                        <FormControl disabled={this.state.userId === '' || this.state.errorMessage !== ''}  type='password' onChange={this.handleChange} value={this.state.retypePassword}></FormControl>
                    </FormGroup>
                    <FormControl disabled={(this.state.password !== this.state.retypePassword) || this.state.password==='' || this.state.errorMessage !== ''} type='submit' className='btn btn-primary my-3' />
                </Form>
                <Alert show={this.state.password !== this.state.retypePassword}>Passwords must match</Alert>
                <Alert variant='danger' show={this.state.errorMessage !== ''}>{this.state.errorMessage}</Alert>
                <div hidden={this.state.errorMessage === ''} >
                    <Link className='btn-link' to='/login'>Back to login page</Link>
                </div>
            </Container>
        )
    }
}