import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface CancelSubProps {
    onCancelResult: (cancelled: boolean) => void,
    show: boolean,
}

export class CancelSub extends React.Component<CancelSubProps, {selectionMade: boolean}> {

    constructor(props:any) {
        super(props);

        this.state = {
            selectionMade: false,
        }

        this.handleCompleteCancel = this.handleCompleteCancel.bind(this);
        this.handleTerminateCancel = this.handleTerminateCancel.bind(this);
    }

    handleCompleteCancel() {
        this.setState({selectionMade: true});
        this.props.onCancelResult(true);
    }

    handleTerminateCancel() {
        this.setState({selectionMade: true});
        this.props.onCancelResult(false);
    }

    render() {
        return (
        <Modal show={this.props.show} className='modal' centered>
            <Modal.Header>
                <h4>Cancel Subscription
                </h4>
            </Modal.Header>
            <Modal.Body className='modal-content'>
                Are you sure you want to proceed?  Once you do, your SmartThings plugin will no longer receive events form
                smartthings.
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.handleTerminateCancel}>No.  Keep my subscription</Button>
                <Button className='btn-danger' onClick={this.handleCompleteCancel}>Yes, please cancel</Button>
            </Modal.Footer>
        </Modal>

        )
    }

}