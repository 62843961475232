
import React from 'react';
import { Button, Container, Form, FormControl, FormGroup } from 'react-bootstrap';
import FormCheckLabel from 'react-bootstrap/esm/FormCheckLabel';
import { RegistrationManager } from '../backend/registrationManager';

interface PasswordResetRequestProps {
    onExit: () => void;
}

interface PasswordResetRequestState {
    emailSent: boolean;
    message: string;
}
export class PasswordResetRequest extends React.Component<PasswordResetRequestProps, PasswordResetRequestState> {

    private _registrationManager: RegistrationManager
    constructor(props: any) {
        super(props);

        this.state = {
            emailSent: false,
            message: '',
        }

        this._registrationManager = new RegistrationManager();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSendUserId = this.handleSendUserId.bind(this);
    }

    handleSubmit(event: any) {
        event.preventDefault();
        const userId = event.target.elements.userId.value;
        if (userId && userId !== '') {
            this._registrationManager.sendPasswordResetEmail(userId);
            this.setState({
                emailSent: true, 
                message: 'An email was sent to the email address associated with this account.  ' +
                'Please check your email and click on the link to complete the password change.'
            });
        }
    }

    handleSendUserId(event: any) {
        this._registrationManager.sendUserIdEmail(event.target.form.elements.userId.value);
        this.setState({
            emailSent: true,
            message: 'An email was sent to the email address associated with this account.  ' +
                'Please check your email.'
        })

    }

    render() {
        return (
            <Container>
            <Form onSubmit={this.handleSubmit}>
                <FormGroup controlId="userId">
                    <FormCheckLabel>Enter your user ID or email address: </FormCheckLabel>
                    <FormControl type='text' name='userId'></FormControl>
                </FormGroup>
                <div className='centered-buttons'>
                    <FormControl disabled={this.state.emailSent} className='btn btn-primary my-4 mx-2' type='submit' value={'Send password reset email'} />
                    <FormControl 
                        disabled={this.state.emailSent} 
                        className='btn btn-primary my-4 mx-2' 
                        type='button' 
                        value={'Send my user ID'} 
                        onClick={this.handleSendUserId}/>
                </div>
            </Form>
            <div hidden={!this.state.emailSent}>
                <p>
                    {this.state.message}
                </p>
                <Button onClick={this.props.onExit}>OK</Button>
            </div>
            </Container>
        )
    }
}