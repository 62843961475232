
import { MainPage } from './components/mainPage';

function App() {
  return (
    <MainPage />
  )
}

export default App;
