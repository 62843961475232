import React from 'react';
import { ReactComponent as Pencil} from '../assets/pencil.svg'

interface EditableFieldProps {
    value: string;
    id: string;
    onChange: (event: EditableFieldChangeEvent) => void;
}

interface EditableFieldState {
    value: string;
    editing: boolean;
}

export interface EditableFieldChangeEvent {
    id: string;
    value: string;
}

export class EditableField extends React.Component<EditableFieldProps, EditableFieldState> {

    constructor(props: any) {
        super(props);

        this.state = {
            value: props.value,
            editing: false,
        }

        this.handleToggle = this.handleToggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleToggle() {
        if (this.state.editing) {
            this.setState({editing: false});
            this.props.onChange({
                id: this.props.id,
                value: this.state.value,
            });
        } else {
            this.setState({editing: !this.state.editing});
        }
    }

    handleChange(event: any) {
        this.setState({ value: event.target.value});
    }

    render() {
        if (this.state.editing) {
            return (
                <div>
                    <input type='text' className='form-control' value={this.state.value} onChange={this.handleChange} onBlur={this.handleToggle} />
                </div>
            )
        } else {
            return (
                <div className='edit-field'>
                    {this.state.value}&nbsp;<Pencil className='edit-button' onClick={this.handleToggle}/>
                </div>
            )
        }
    }
}