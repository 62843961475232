import React, { FormEvent } from "react";
import { Container, Form, Alert, Row, Col, Modal } from 'react-bootstrap';
import { Link, Navigate } from "react-router-dom";
import { LoginManager } from "../backend/loginManager";
import { PasswordResetRequest } from "./passwordResetRequest";

interface LoginState {
    userId: string;
    password: string;
    showError: boolean;
    errorMessage: string;
    loginButtonEnabled: boolean;
    loginSuccess: boolean;
    passwordEmailReset: boolean;
}
export class Login extends React.Component<{ onLoginResult: (userId: string | undefined) => void,
    onHowTo?: () => void }, LoginState> {

    loginManager: LoginManager = new LoginManager();

    constructor(props: any) {
        super(props);

        this.state = {
            userId: '',
            password: '',
            showError: false,
            errorMessage: '',
            loginButtonEnabled: false,
            loginSuccess: false,
            passwordEmailReset: false,
        }

        this.handleLogin = this.handleLogin.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    async handleLogin(event: FormEvent) {
        event.preventDefault();

        if (!this.state.userId || !this.state.password) {
            this.setState({ showError: true });
            return;
        }

        const accessToken = await this.loginManager.login(this.state.userId, this.state.password);
        if (!accessToken) {
            this.setState({ errorMessage: 'User id or password not found', showError: true });
            return;
        } else {
            this.props.onLoginResult(this.state.userId);
            this.setState({loginSuccess: true});
        }
    }

    handleChange(event: any) {
        this.setState({ [event.target.id]: event.target.value } as unknown as LoginState, () => {
            if (!this.state.userId || !this.state.password) {
                this.setState({ loginButtonEnabled: false });
            } else {
                this.setState({ loginButtonEnabled: true });
            }
        });
    }


    render() {
        return (
            <Container fluid='md'>
                {this.state.loginSuccess && <Navigate to='/account' />}
                <Row>
                    <Col md>
                        <div className='text-block text-center'>
                            <p>
                                Without webhook capability, the plugin will poll each device based on settings,
                                but no more frequent than every 5 seconds.  In addition, if you have a large number
                                of devices, the frequent polling can cause network conjestion and errors.  You may also
                                encounter api limits from SmartThings.
                            </p>
                            <p>
                                Samsung supports a webhook "smart app" which allows for the plugin to wait for an event
                                instead of polling constantly.  This results in close to real-time updates from devices
                                including sensors like motion detectors, without creating a lot of traffic on your network.
                            </p>
                            <p>
                                Sign up for Homebridge SmarThings webhook services to solve this problem.  The service costs $2.99
                                per month with your first 7 days free.  I'm charging for this service because I am paying for cloud computing resources, and I've
                                put many hourse of development into the plugin and web hook service.
                            </p>
                            <p><strong>
                                IMPORTANT: Please read through the How To <Link to={'/howto'}>here</Link> before signing
                                up.  You need to configure a "Smart App" in the Developer Workspace attached to your SmartThings account.
                            </strong></p>
                        </div>
                    </Col>
                    <Col md>
                        <Container className='centered-block'>
                            <p style={{ textAlign: "center" }}>Enter your user id and password</p>
                            <Form onSubmit={this.handleLogin}>
                                <Form.Group controlId="userId" >
                                    <Form.Label>User Id:</Form.Label>
                                    <Form.Control
                                        name="userId"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={this.state.userId}
                                    />
                                </Form.Group>
                                <Form.Group controlId="password" >
                                    <Form.Label>Password:</Form.Label>
                                    <Form.Control
                                        name="password"
                                        type="password"
                                        onChange={this.handleChange}
                                        value={this.state.password}
                                    />
                                </Form.Group>
                                <div className="centered-buttons">
                                    <input type="submit" value='Login' className='btn btn-primary' disabled={!this.state.loginButtonEnabled} />
                                </div>
                            </Form>
                            <div className="centered-buttons">
                                <Link className="btn-link" to={'/register'}>Click here to register</Link>
                            </div>
                            <div className="centered-buttons my-3">
                                <div className="btn btn-link" onClick={() => this.setState({passwordEmailReset: true})}>Forgot password or User Id</div>
                            </div>
                            <Alert hidden={!this.state.showError} variant="danger">{this.state.errorMessage}</Alert>
                        </Container>
                    </Col>
                </Row>
                <Modal show={this.state.passwordEmailReset} className="modal modal-password-reset" onHide={() => this.setState({passwordEmailReset: false})}>
                    <Modal.Header closeButton className="header"><h3>Password Reset</h3></Modal.Header>
                    <Modal.Body className='modal-content'>
                        <PasswordResetRequest onExit={() => this.setState({passwordEmailReset: false})} />
                    </Modal.Body>
                </Modal>
            </Container>
        )
    }
}