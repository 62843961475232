import axios, { AxiosInstance } from 'axios';
import * as settings from './settings';

var wwwToken = settings.wwwAccessToken;
var userToken: string = '';
var axiosInstance: AxiosInstance | undefined;

export function setUserToken(token: string) {
    userToken = token;
}

export function getUserToken(): string {
    return userToken;
}

export function getAxiosInstance(tokenType: 'WWW' | 'USER' = 'WWW'): AxiosInstance {
    if (!axiosInstance) {
        axiosInstance = axios.create({ baseURL: settings.stwhServicesURL });
    }
    let token;
    if (tokenType === 'WWW') {
        token = wwwToken;
    } else {
        token = userToken;
    }

    if (token === '') {
        throw (new Error('Token not set'));
    }

    axiosInstance.defaults.headers.common = { Authorization: 'Bearer: ' + token };
    return axiosInstance;

}