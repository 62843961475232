import React from 'react';
import { AccountListing } from './accountListing';
import { RegistrationManager } from '../backend/registrationManager';
import { InstalledApp, Registration } from '../../../stwh-services/build/types';
import { CancelSub } from './cancelSub';
import { Navigate } from 'react-router-dom';

interface AccountState {
    registration: Registration;
    installedApp: InstalledApp | undefined;
    cancelInProgress: boolean;
}
export class Account extends React.Component<{ userId: string, onEnd: () => void }, AccountState> {
    registrationManager = new RegistrationManager();
    fetchingAccount = false;

    constructor(props: any) {
        super(props);

        this.state = {
            registration: {
                userId: '',
                emailAddress: '',
                firstName: '',
                lastName: '',
                accessToken: '',
                created: new Date(),
                modified: new Date(),
                payPalSubId: '',
            },
            installedApp: {
                accessToken: '',
                installedAppId: '',
                installed: new Date('1970-01-01'),
                lastActivity: new Date('1970-01-01'),
                numDevices: 0,
            },
            cancelInProgress: false,
        }

        this.handleEnd = this.handleEnd.bind(this);
        this.handleCancelSub = this.handleCancelSub.bind(this);

    }

    componentDidMount(): void {
        if (!this.props.userId || this.props.userId === '') {
            return;
        }
        if (!this.state.registration.userId && !this.fetchingAccount)  {
            this.fetchingAccount = true;
            this.registrationManager.getRegistration(this.props.userId).then((reg) => {
                if (reg.accessToken) {
                    this.registrationManager.getInstalledApp(reg.accessToken).then((installedApp) => {
                        this.setState({
                            registration: reg,
                            installedApp: installedApp,
                        });
                    });
                } else {
                    this.setState({
                        registration: reg,
                        installedApp: undefined,
                    })
                }
                this.fetchingAccount = false;
            })
        }
    }

    handleEnd() {
        this.props.onEnd();
    }

    async handleCancelSub(cancel: boolean) {
        this.setState({cancelInProgress: false});
        if (cancel) {
            await this.registrationManager.unregister(this.state.registration.userId);
            this.props.onEnd();
        }
    }


    render() {
        if (!this.props.userId) {
            return <Navigate to='/login' />
        }
        if (this.state.registration.userId) {
            return (
                <div>
                    <AccountListing onEnd={this.handleEnd} registration={this.state.registration} installedApp={this.state.installedApp} />
                    <div className='centered-buttons'>
                        <div className='btn-link' onClick={() => this.setState({ cancelInProgress: true })}>Click here to cancel your subscription</div>
                        <CancelSub show={this.state.cancelInProgress} onCancelResult={this.handleCancelSub} />
                    </div>
                </div>
            )
        } 
    }
}