// import { AxiosInstance} from 'axios';
import { getAxiosInstance, setUserToken, getUserToken } from './axiosFactory';

export class LoginManager {
    // axios: AxiosInstance;

    // constructor() {
    //     this.axios = getAxiosInstance();
    // }

    async login(userId: string, password: string): Promise<string | undefined> {

        try {
            const resp = await getAxiosInstance().post('/login', {
                userId: userId,
                password: password
            })
            setUserToken(resp.data.token);
            return resp.data.token;
        } catch (error) {
            return undefined;
        }
    }

    async logout() {
        await getAxiosInstance('USER').post('/logout', {
            token: getUserToken(),
        })
    }
}
