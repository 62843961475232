// import { AxiosInstance } from 'axios';
import { InstalledApp, Registration } from '../../../stwh-services/build/types'
import { getAxiosInstance } from './axiosFactory';

export class RegistrationManager {
    // axios: AxiosInstance;

    // constructor() {
    //     getAxiosInstance() = getAxiosInstance();
    // }

    async createRegistration(userId: string, password: string, emailAddress: string, firstName: string, lastName: string): Promise<string> {
        const resp = await getAxiosInstance().post('/register', {
            userId: userId,
            password: password,
            firstName: firstName,
            lastName: lastName,
            emailAddress: emailAddress
        });

        return resp.data.accessToken as string;
    }

    async getRegistration(userId: string): Promise<Registration> {
        const reg = (await getAxiosInstance('USER').get('/getregistration?userid=' + userId)).data as unknown as Registration;
        return reg;
    }

    async getRegistrations(): Promise<Registration[]> {
        try {
            const resp = await getAxiosInstance('USER').get('/getregistrations');
            return resp.data as unknown as Registration[];
        } catch (error) {
            // alert('Communication error: ' + String(error));
            return [];
        }
    }

    async updateRegistration(registration: Partial<Registration>) {
        await getAxiosInstance('USER').post('/updateRegistration?userid=' + registration.userId, registration);
    }

    async unregister(userId: string) {
        await getAxiosInstance('USER').delete('/unregister?userid=' + userId);
    }

    async alreadyRegistered(userId: string): Promise<boolean> {
        const result = (await getAxiosInstance().get('/checkexistingregistration?userid=' + userId)).data;
        return (result.found);
    }

    async checkUniqueFields(userId: string, emailAddress: string): Promise<'REGISTERED' | 'EMAILUSED' | 'OK'> {
        if (await this.alreadyRegistered(userId)) {
            return 'REGISTERED';
        }

        const result = (await getAxiosInstance().get('/checkexistingemail?emailaddress=' + emailAddress)).data;
        if (result.found) {
            return 'EMAILUSED';
        } else {
            return 'OK';
        }
    }

    async validateSubscription(userId: string, subscriptionId: string): Promise<string | false> {
        try {
            const resp = await getAxiosInstance().post('/validateSubscription', {
                userId: userId,
                subscriptionId: subscriptionId,
            });

            if (resp.data.accessToken) {
                return resp.data.accessToken;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }

    async getInstalledApp(accessToken: string): Promise<InstalledApp|undefined> {
        try {
            const resp =  await getAxiosInstance('USER').get('/getinstalledapp?accesstoken=' + accessToken);
            const installedApp = resp.data as InstalledApp;

            if (installedApp.installedAppId) {
                installedApp.installed = new Date(installedApp.installed as unknown as string);
                installedApp.lastActivity = new Date(installedApp.lastActivity as unknown as string);
                return installedApp;
            } else {
                return undefined;
            }
        } catch (error) {
            return undefined;
        }
        
    }

    async sendPasswordResetEmail(userId: string): Promise<void> {
        await getAxiosInstance('WWW').get('/changepasswordrequest?userid=' + userId);
    }

    async validateChangePasswordToken(token: string): Promise<string | undefined> {
        try {
            return (await getAxiosInstance('WWW').get('/validatechangepwtoken?token=' + token)).data.userId;
        } catch(error) {
            return undefined;
        }
    }

    async changePassword(userId: string, token: string, password: string): Promise<boolean> {
        try {
            await getAxiosInstance('WWW').post('/changepassword', {
                userId: userId,
                token: token,
                password: password,
            });
            return true;
        } catch(error) {
            return false;
        }
    }

    async sendUserIdEmail(emailAddress: string) {
        try {
            await getAxiosInstance('WWW').get('/getuserid?emailaddress=' + emailAddress);
        } catch(error) {
            // Ignore
        }
    }
}