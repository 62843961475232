import React from 'react';
import { Container } from 'react-bootstrap';
import { PayPalButtons  } from '@paypal/react-paypal-js';
import { RegistrationManager } from '../backend/registrationManager';
import { payPalPlanId } from '../backend/settings';


export class Payment extends React.Component<{userId: string, onPayment: (accessToken:string|false) => void, disabled?: boolean}> {
    registrationManager = new RegistrationManager();
 
    constructor(props: any) {
        super(props);
        this.handleApproval = this.handleApproval.bind(this);
    }

    async handleApproval(data: any, actions: any) {
        if (data.subscriptionID) {
            const accessToken = await this.registrationManager.validateSubscription(this.props.userId, data.subscriptionID);
            this.props.onPayment(accessToken);
        } else {
            this.props.onPayment(false);
        }
    }

    render() {
        return (
            <Container id='container'>                
                <p>The webhook service is just $2.99 per month.  Sign up using one of the following methods.</p>
                    <PayPalButtons disabled={this.props.disabled} style={{
                        shape: 'rect',
                        color: 'gold',
                        layout: 'vertical',
                        label: 'subscribe'
                    }} createSubscription={(data, actions) => {
                        return actions.subscription.create({ plan_id: payPalPlanId });
                    }} onApprove={this.handleApproval}></PayPalButtons>
            </Container>
        )
    }
}