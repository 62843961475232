import React from 'react';

export class NotFound extends React.Component {
    render() {
        return (
            <div><h1>Not Found</h1>
            <p>
                <a href='/'>Go back to home page</a>
            </p>
        </div>
        )
    }
}