import React from 'react';
import { Modal, Button, Container } from 'react-bootstrap';
import { RegistrationForm, RegistrationData } from './registrationForm';
import { RegistrationManager } from '../backend/registrationManager';
import { LoginManager } from '../backend/loginManager';
import { Payment } from './Payment';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { payPalClientId } from '../backend/settings';
import { Link } from 'react-router-dom';

export class Register extends React.Component<{ onRegister: (userId: string | undefined) => void }, {
    registrationFormSubmitted: boolean,
    regData: RegistrationData,
    paymentOutcome: 'PENDING' | 'SUCCESS' | 'FAILED' | 'CANCELLED',
    accessToken: string,
}> {

    registrationManager: RegistrationManager = new RegistrationManager();
    loginManager = new LoginManager();

    constructor(props: any) {
        super(props);

        this.state = {
            registrationFormSubmitted: false,
            regData: {} as RegistrationData,
            paymentOutcome: 'PENDING',
            accessToken: '',
        }
        this.handleRegisterUpdate = this.handleRegisterUpdate.bind(this);
        this.handlePaymentComplete = this.handlePaymentComplete.bind(this);
        this.handleRegistrationComplete = this.handleRegistrationComplete.bind(this);
        this.handlePaymentCancel = this.handlePaymentCancel.bind(this);
        this.handleCancelAcknowledged = this.handleCancelAcknowledged.bind(this);
    }

    async handleRegisterUpdate(reg: RegistrationData) {
        console.log('Registration Updated: ' + JSON.stringify(reg));
        await this.loginManager.login(reg.userId, reg.password);
        this.setState({ regData: reg, registrationFormSubmitted: true, });
    }

    // renderPayment() {
    //     if (this.state.registrationSuccess) {
    //         return <Payment userId={this.state.userId} />
    //     }
    // }

    async handlePaymentComplete(accessToken: string | false) {
        console.log(`Payment complete: ${accessToken}`);
        if (accessToken) {
            this.setState({
                accessToken: accessToken,
                paymentOutcome: 'SUCCESS',
            });
        } else {
            this.setState({ paymentOutcome: 'FAILED' });
            this.registrationManager.unregister(this.state.regData.userId);
        }
        // this.props.onRegister(true);

        // await this.registrationManager.createRegistration(
        //     this.state.regData.userId,
        //     this.state.regData.password,
        //     this.state.regData.emailAddress,
        //     this.state.regData.firstName,
        //     this.state.regData.lastName
        // );
    }


    handlePaymentCancel() {
        this.setState({ paymentOutcome: 'CANCELLED' });
        this.registrationManager.unregister(this.state.regData.userId);
        // this.props.onRegister(false);
    }

    handleCancelAcknowledged() {
        this.props.onRegister(undefined);
    }

    handleRegistrationComplete() {
        // this.setState({ paymentOutcome: 'SUCCESS' })
        console.log('Registration complete');
        this.props.onRegister(this.state.regData.userId);
    }

    render() {
        return (
            <div>
                <PayPalScriptProvider options={{
                    "client-id": payPalClientId,
                    intent: "subscription",
                    vault: true,
                    "data-sdk-integration-source": "button-factory"
                }}>
                    <Modal show={this.state.paymentOutcome === 'CANCELLED'} className='modal' centered>
                        <Modal.Header>
                            <h4>Registration Cancelled
                            </h4>
                        </Modal.Header>
                        <Modal.Body className='modal-content'>
                            Your registration was cancelled.  If you still want to register, try again.
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.handleCancelAcknowledged}>OK</Button>
                        </Modal.Footer>
                    </Modal>
 
                    <Modal show={this.state.paymentOutcome === 'SUCCESS'} className='modal' centered>
                        <Modal.Header>
                            <h4>Subscription Success
                            </h4>
                        </Modal.Header>
                        <Modal.Body className='modal-content'>
                            Your access token is<br />{this.state.accessToken}<br />
                            Save this code, use it to register your webhook smart app, and to set the "WebhookToken" value in your plugin configuration.
                        </Modal.Body>
                        <Modal.Footer>
                                <Link className='btn btn-primary' onClick={this.handleRegistrationComplete} to={'/account'}>OK</Link>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={this.state.registrationFormSubmitted && this.state.paymentOutcome === 'PENDING'} className='modal' centered onHide={this.handlePaymentCancel} >
                        <Modal.Header closeButton><h4>Payment</h4></Modal.Header>
                        <Modal.Body className='modal-content'>
                            <Payment onPayment={this.handlePaymentComplete} userId={this.state.regData.userId} />
                        </Modal.Body>
                    </Modal>
                    <Container fluid='lg' className='text-center'>
                        <p>
                            Complete the form below and choose 'Save'. You can then use one of three methods (powered by PayPal) to set up your
                            subscription.  Once complete, you will be presented with your access token.  You can log in to this site at any 
                            time to retrieve your token.
                        </p>
                        <p>
                            Thanks for your support! 
                        </p>
                    </Container>
                    <RegistrationForm onUpdate={this.handleRegisterUpdate} onQuit={this.handleCancelAcknowledged} />
                 </PayPalScriptProvider>
            </div>
        )
    }
}