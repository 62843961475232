import React from 'react';
import { Button, Col, Container, Row, Modal } from 'react-bootstrap';
import { InstalledApp, Registration } from '../../../stwh-services/build/types';
import { EditableField, EditableFieldChangeEvent } from './editableField';
import { RegistrationManager } from '../backend/registrationManager';

interface AccountListingState {
    userId: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    commError: boolean;
}

interface AccountListingProps {
    registration: Registration;
    installedApp: InstalledApp | undefined;
    onEnd: () => void;
}

export class AccountListing extends React.Component<AccountListingProps, AccountListingState> {

    registrationManager: RegistrationManager;
    constructor(props: any) {
        super(props);

        this.state = {
            userId: props.registration.userId,
            emailAddress: props.registration.emailAddress,
            firstName: props.registration.firstName,
            lastName: props.registration.lastName,
            commError: false,
        }
        this.registrationManager = new RegistrationManager();

        this.handleChange = this.handleChange.bind(this);
    }

    async handleChange(event: EditableFieldChangeEvent) {
        try {
            await this.registrationManager.updateRegistration({
                userId: this.state.userId,
                [event.id]: event.value
            })
            this.setState({ [event.id]: event.value } as unknown as AccountListingState);
        } catch (error) {
            this.setState({commError: true})
        }
    }

    render() {
        return (
            <Container fluid='lg' className='left-justify bg-light my-3'>
                <Modal show={this.state.commError} className='modal' centered>
                    <Modal.Header>
                        <h4>Error sending update
                        </h4>
                    </Modal.Header>
                    <Modal.Body className='modal-content'>
                        You session may have timed out.  Log in again.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.props.onEnd()}>OK</Button>
                    </Modal.Footer>
                </Modal>

                <Row className='data-display-row'>
                    <Col sm className='listing-label'>
                        User ID:
                    </Col>
                    <Col sm>
                        {this.state.userId}
                    </Col>
                    <Col sm className='listing-label'>
                        Email address:
                    </Col>
                    <Col sm>
                        <EditableField id='emailAddress' value={this.state.emailAddress} onChange={this.handleChange} />
                    </Col>
                </Row>
                <Row className='data-display-row'>
                    <Col sm className='listing-label'>
                        First Name:
                    </Col>
                    <Col sm>
                        <EditableField id='firstName' value={this.state.firstName} onChange={this.handleChange} />
                    </Col>
                    <Col sm className='listing-label'>
                        Last Name:
                    </Col>
                    <Col sm>
                        <EditableField id='lastName' value={this.state.lastName} onChange={this.handleChange} />
                    </Col>
                </Row>
                <hr></hr>
                <Row className='data-display-row'>
                    <Col sm className='listing-label'>
                        Web Hook Access Token:
                    </Col>
                    <Col sm>
                        {this.props.registration.accessToken}
                    </Col>
                </Row>
                <Row className='data-display-row'>
                    <Col sm className='listing-label'>
                        Smart App Installed On:
                    </Col>
                    <Col sm>
                        {this.props.installedApp ?
                            this.props.installedApp.installed.toLocaleString() : 'Not Yet Installed'}
                    </Col>
                </Row>
                <Row className='data-display-row'>
                    <Col sm className='listing-label'>
                        Number of devices monitored:
                    </Col>
                    <Col sm>
                        {this.props.installedApp ?
                            this.props.installedApp.numDevices : '0'}
                    </Col>
                </Row>

            </Container>
        )
    }
}