
// const STWH_SERVICES_URL = 'http://localhost:8080';
let STWH_SERVICES_URL:string;
let WWW_ACCESS_TOKEN: string;
let PAYPAL_PLAN_ID: string;
let PAYPAL_CLIENT_ID: string;

if (process.env.NODE_ENV === 'development') {
    STWH_SERVICES_URL = 'http://192.168.1.23:8080';
    WWW_ACCESS_TOKEN = 'e6e716d0-b532-11ed-832e-db583384be41';
    PAYPAL_PLAN_ID = 'P-0WH7778180153801WMPX5ABY';
    PAYPAL_CLIENT_ID = 'AWAgv_pzqIDTzr1hmm7lB6buwmFasuchfXbrRc9DAhuhNxzrduhQ1IzjQ0PFtqmpCI_V9yZG7CKbH5NB';
} else {
    STWH_SERVICES_URL = 'https://stwh.kleinstudios.net/services';
    WWW_ACCESS_TOKEN = 'e6e716d0-b532-11ed-832e-db583384be41';
    PAYPAL_PLAN_ID = 'P-9EK26608Y41928144MP5B7EI';
    PAYPAL_CLIENT_ID = 'Adki86_UwrMiK5we1ikcnUIJYJXwvNApXkfipObEZZkdCFgm0AZ6oKSKtnKUzn01dH7ebkEW9d64IfEg';
}

//const stwhServicesURL = process.env.STWH_SERVICES_URL ? process.env.STWH_SERVICES_URL : STWH_SERVICES_URL;

export const stwhServicesURL = STWH_SERVICES_URL;
export const wwwAccessToken = WWW_ACCESS_TOKEN;
export const payPalPlanId = PAYPAL_PLAN_ID;
export const payPalClientId = PAYPAL_CLIENT_ID;
export {}
