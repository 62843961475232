import React from 'react';
import { Register } from './register';
import { Login } from './login';
import { Nav } from 'react-bootstrap';
import { Account } from './account';
import { LoginManager } from '../backend/loginManager';
import { HowTo } from './howto';
import { BrowserRouter, Route, Link, Navigate, Routes } from 'react-router-dom';
import { NotFound } from './notFound';
import { ChangePasswordPage } from './changePassword';

type displayType = 'LOGIN' | 'REGISTER' | 'ACCOUNT' | 'HOWTO';
interface MainPageState {
    loggedIn: boolean;
    display: displayType;
    prevDisplay: displayType;
    userId: string | undefined;
}

export class MainPage extends React.Component<{}, MainPageState> {

    loginManager = new LoginManager();

    constructor(props: any) {
        super(props);

        this.state = {
            loggedIn: false,
            display: 'LOGIN',
            prevDisplay: 'LOGIN',
            userId: undefined,
        }

        this.handleLoginResult = this.handleLoginResult.bind(this);
        this.handleRegisterComplete = this.handleRegisterComplete.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLoginResult(userId: string | undefined) {
        if (userId) {
            this.setState({
                loggedIn: true, display: 'ACCOUNT',
                userId: userId,
            })
        } else {
            this.setState({
                loggedIn: false, display: 'REGISTER',
                userId: undefined,
            });
        }
    }

    handleRegisterComplete(userId: string | undefined) {
        if (userId) {
            this.changeDisplayState('ACCOUNT');
            this.setState({ userId: userId, loggedIn: true });
            //Navigate({ to: '/account' });
        } else {
            this.changeDisplayState('LOGIN');
            this.setState({ userId: '', loggedIn: false });
            //Navigate({ to: '/login' });
        }
    }

    renderBody() {
        switch (this.state.display) {
            case 'LOGIN':
                return <Navigate to='/login' />

            case 'REGISTER':
                return <Navigate to='/register' />

            case 'ACCOUNT':
                return <Navigate to='/account' />

            case 'HOWTO':
                return <Navigate to='/howto' />

        }
    }

    async handleLogout() {
        try {
            await this.loginManager.logout();
        } catch(error) {
            // Ignore 
        }

        this.setState({
            display: 'LOGIN',
            loggedIn: false,
            userId: undefined,
        })
    }

    changeDisplayState(newDisplay: displayType) {
        this.setState({
            prevDisplay: this.state.display,
            display: newDisplay,
        })
    }

    getPathArgs(): string[]
    {
        // eslint-disable-next-line no-restricted-globals
        const tokens = location.pathname.split('/');
        return tokens.slice(2);
    }

    render() {
        return (
            <div>
                <BrowserRouter>
                    <header className='masthead sticky-header'>
                        <Nav>
                            <Nav.Item hidden={this.state.loggedIn}>
                                {/* <Nav.Link onClick={() => this.changeDisplayState('LOGIN')}>Login/Register</Nav.Link> */}
                                <Link className='nav-link' to={'/login'} >Login/Register</Link>
                            </Nav.Item>
                            <Nav.Item hidden={!this.state.loggedIn}>
                                <Link className='nav-link' onClick={this.handleLogout} to={'/login'}>Logout</Link>
                                {/* <Nav.Link onClick={this.handleLogout}>Logout</Nav.Link> */}
                            </Nav.Item>
                            <Nav.Item>
                                <Link className='nav-link' to={'howto'}>How To</Link>
                                {/*<Nav.Link onClick={() => this.changeDisplayState('HOWTO')}>How To</Nav.Link> */}
                            </Nav.Item>
                        </Nav>
                        <div className="masthead-heading">Homebridge SmartThings</div>
                        <div className="masthead-subheading text-uppercase">Webhook Signup</div>
                    </header>
                    { /* !this.state.loggedIn && <Navigate to='/login' /> */ } 
                    <Routes>
                        <Route path='/' element={<Login onLoginResult={this.handleLoginResult} />} />
                        <Route path='/login' element={<Login onLoginResult={this.handleLoginResult} />} />
                        <Route path='/howto' element={<HowTo onReturn={() => this.changeDisplayState(this.state.prevDisplay)} />} />
                        <Route path='/account' element={<Account userId={this.state.userId as string} onEnd={this.handleLogout} />} />
                        <Route path='/register' element={<Register onRegister={this.handleRegisterComplete} />} />
                        <Route path='/resetpassword/:token/:emailAddress' element={<ChangePasswordPage token={this.getPathArgs()[0]} emailAddress={this.getPathArgs()[1]}/>} />
                        <Route path='*' element={<NotFound />} />
                    </Routes>

                    {/* this.renderBody() */}
                </BrowserRouter>
            </div>
        )
    }
}